import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useLocation } from "@reach/router"
import {
  Container,
  Tab,
  Nav,
  Row,
  Col,
  ListGroup,
  Card,
  Accordion,
} from "react-bootstrap"
import { graphql, Link } from "gatsby"
import { FaChevronRight } from "react-icons/fa"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import GlobalContext from "../contexts/GlobalContext"

import { Box } from "../components/Core"
import { slugCat, slugProd, slugHome } from "../utils/slugs"
import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Text } from "../components/Core"
import renderSlices from "../components/Slices"
import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg4.jpg"

const PageWrapperStyledSubCats = styled(PageWrapper)`
  .breadcrumb-link {
    font-weight: 600;
    color: ${({ theme, colorBrand }) =>
      colorBrand ? colorBrand : theme.colors.primary} !important;

    &:hover,
    &:active,
    &:focus,
    &.active {
      text-decoration: none !important;
      outline: none !important;
      color: ${({ theme, colorBrand }) =>
        colorBrand ? colorBrand : theme.colors.primary} !important;
    }
  }

  article a,
  table tr td p a {
    color: ${({ theme, colorHover }) =>
      colorHover ? colorHover : theme.colors.secondary} !important;
    transition: all 0.15s ease-out;
    user-select: none;
    text-decoration: underline !important;
    &:hover,
    &:active,
    &:focus,
    &.active {
      text-decoration: underline !important;
      outline: none !important;
      color: ${({ theme, colorHover }) =>
        colorHover ? colorHover : theme.colors.secondary} !important;
    }
  }
  table.table .table-head {
    background: ${({ theme, colorBrand }) =>
      colorBrand ? colorBrand : theme.colors.primary} !important;
    th p {
      color: #fff !important;
    }
  }

  .list-group-item,
  .list-group-item.disable-active.active,
  .accordion-card-subcat,
  .accordion-card-subcat > .card-header {
    background: ${({ theme }) => theme.colors.lightShade} !important;
    transition: all 0.2s ease-out;
    border-color: ${({ theme }) => theme.colors.border} !important;
    user-select: none;
  }
  .list-group-item,
  .list-group-item.disable-active.active a {
    color: ${({ theme }) => theme.colors.text} !important;
    &:hover,
    &:active,
    &:focus,
    &.active {
      text-decoration: none;
      outline: none !important;
      color: ${({ theme }) => theme.colors.text} !important;
    }
  }

  .accordion-card-subcat > .card-header {
    font-weight: 600;
  }

  .list-group-item:not(.active):hover,
  .card-body,
  .accordion-card-subcat:not(.active) > .card-header:hover {
    color: ${({ theme }) => theme.colors.dark} !important;
    background-color: #ffffff !important;
  }

  .list-group-item.active,
  .accordion-card-subcat.active > .card-header {
    color: #fff !important;
    background: ${({ theme, colorBrand }) =>
      colorBrand ? colorBrand : theme.colors.primary} !important;
    border-color: ${({ theme }) => theme.colors.border} !important;
  }
`

const AccordionStyled = styled(Accordion)`
  .card-header {
    cursor: pointer;
    font-weight: bold;
  }
  ul {
    margin-bottom: 0;
  }

  .accordion-header {
    position: relative;
    padding-right: 40px;
    &.active a {
      color: #fff !important;
    }
  }
`

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  position: absolute;
  right: 5px;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;

  & > svg {
    transition: 0.3s;
  }

  &.active > svg {
    color: #fff;
    transform: rotate(90deg);
  }
`

const SubCategory = ({ data }) => {
  const [activeKeyOption, setActiveKeyOption] = useState(null)
  const [activeKeySpec, setActiveKeySpec] = useState(null)
  const [activeKeyProduct, setActiveKeyProduct] = useState(null)
  const [tabActiveKey, setTabActiveKey] = useState("info")

  const { pathname } = useLocation()
  const gContext = useContext(GlobalContext)

  const cat = data.prismicSubCategory

  const allProductsArr = data.allPrismicProduct.nodes.filter(
    ({ data }) => data.sub_category.uid === cat.uid
  )

  const allProducts = allProductsArr.filter(
    ({ data }) => data.type === "Product"
  )
  const allOptions = allProductsArr.filter(({ data }) => data.type === "Option")
  const allSpecs = allProductsArr.filter(
    ({ data }) => data.type === "Specification"
  )

  const {
    body: slices = [],
    has_product_database,
    has_technical_overview,
    btn_product_database_title,
    btn_technical_overview_title,
    sub_category_title,
    sub_category_info_title,
    products_title,
    options_title,
    specifications_title,
  } = cat.data

  const catParent = cat?.data?.category.document
  const {
    color_brand = "red",
    color_hover = "red",
    color_active = "red",
  } = catParent?.data || {
    color_brand: "red",
    color_hover: "red",
    color_active: "red",
  }

  const { btn_back, btn_home, btn_request_sample } = data.prismicCommon?.data

  useEffect(() => {
    if (allProducts.length > 0) setActiveKeyProduct(allProducts[0].uid)
    if (allOptions.length > 0) setActiveKeyOption(allOptions[0].uid)
    if (allSpecs.length > 0) setActiveKeySpec(allSpecs[0].uid)
  }, [])

  useEffect(() => {
    if (gContext.currentLang !== cat.lang) {
      gContext.setCurrentLang(cat.lang)
    }
  }, [])

  return (
    <>
      <GatsbySeo
        title={cat.data.meta_title?.text || cat.data.title?.text}
        description={cat.data.meta_description?.text}
      />
      <PageWrapperStyledSubCats
        colorBrand={color_brand}
        colorActive={color_active}
        colorHover={color_hover}
      >
        <Hero
          bgImg={
            cat.data.cover_image.url ||
            (catParent && catParent.data.cover_image.url) ||
            bgImg
          }
        >
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {cat.data.title && (
                  <Title variant="hero" color="light" mb={3}>
                    {cat.data.title.text}
                  </Title>
                )}
                {catParent && (
                  <>
                    <div className="d-flex my-3 justify-content-center">
                      <div className="text-white font-weight-bold">
                        {">>"}
                        <Link
                          to={slugHome(gContext.currentLang)}
                          className="text-white font-weight-bold"
                        >
                          {btn_home && btn_home.text.length > 0
                            ? btn_home.text
                            : "Home"}
                        </Link>
                      </div>
                      <div className="text-white font-weight-bold ml-2">
                        {">>"}
                        <Link
                          to={slugCat(catParent.uid, gContext.currentLang)}
                          className="text-white font-weight-bold"
                        >
                          {catParent.data.title.text}
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9">
                <Tab.Container
                  activeKey={tabActiveKey}
                  activeKey={tabActiveKey}
                >
                  <Row>
                    <Col md={3} className="mb-5 mb-lg-0">
                      {catParent && (
                        <>
                          <div className="d-flex my-3 justify-content-start">
                            <div className="text-white font-weight-bold ml-2">
                              <Link
                                to={slugCat(
                                  catParent.uid,
                                  gContext.currentLang
                                )}
                                className="text-dark font-weight-bold"
                              >
                                {"<<"}{" "}
                                {btn_back && btn_back.text.length > 0
                                  ? btn_back.text
                                  : "Back"}
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                      <ListGroup
                        css={`
                          .list-group-flush:first-child,
                          .list-group-item:first-child {
                            border-top: 1px solid rgba(24, 59, 86, 0.115);
                          }
                          .active.nav-link {
                            color: white !important;
                          }
                        `}
                      >
                        <ListGroup.Item
                          as={Nav.Link}
                          className={tabActiveKey === "info" ? "active" : ""}
                          onClick={() => setTabActiveKey("info")}
                        >
                          {sub_category_info_title &&
                          sub_category_info_title.text.length > 0
                            ? sub_category_info_title.text
                            : "Sub-Cat Info"}
                        </ListGroup.Item>

                        {allProducts.length > 0 && (
                          <ListGroup.Item
                            as={Nav.Link}
                            className={
                              tabActiveKey === "products" ? "active" : ""
                            }
                            onClick={() => setTabActiveKey("products")}
                          >
                            {products_title && products_title.text.length > 0
                              ? products_title.text
                              : "Products"}
                            <small>({allProducts.length})</small>
                          </ListGroup.Item>
                        )}

                        {allOptions.length > 0 && (
                          <ListGroup.Item
                            as={Nav.Link}
                            className={
                              tabActiveKey === "options" ? "active" : ""
                            }
                            onClick={() => setTabActiveKey("options")}
                          >
                            {options_title && options_title.text.length > 0
                              ? options_title.text
                              : "Options"}
                            <small>({allOptions.length})</small>
                          </ListGroup.Item>
                        )}
                        {allSpecs.length > 0 && (
                          <ListGroup.Item
                            as={Nav.Link}
                            className={tabActiveKey === "specs" ? "active" : ""}
                            onClick={() => setTabActiveKey("specs")}
                          >
                            {specifications_title &&
                            specifications_title.text.length > 0
                              ? specifications_title.text
                              : "Specifications"}
                            <small>({allSpecs.length})</small>
                          </ListGroup.Item>
                        )}

                        {cat.data.docs_group.length > 0 &&
                          cat.data.docs_group.map(
                            ({ title1, doc_link }, index) => {
                              return (
                                doc_link.url && (
                                  <ListGroup.Item
                                    as="a"
                                    href={doc_link.url}
                                    target="_blank"
                                    className="disable-active"
                                    key={index}
                                    onClick={() => setTabActiveKey("info")}
                                  >
                                    {title1 ? title1.text : `PDF ${index + 1}`}
                                  </ListGroup.Item>
                                )
                              )
                            }
                          )}

                        {has_product_database && (
                          <ListGroup.Item
                            as={Link}
                            to="/product-database"
                            className="disable-active"
                          >
                            {btn_product_database_title &&
                            btn_product_database_title.text.length > 0
                              ? btn_product_database_title.text
                              : "Product Database"}
                          </ListGroup.Item>
                        )}

                        {has_technical_overview && (
                          <ListGroup.Item
                            as={Link}
                            to="/technical-overview"
                            className="disable-active"
                          >
                            {btn_technical_overview_title &&
                            btn_technical_overview_title.text.length > 0
                              ? btn_technical_overview_title.text
                              : "Technical Overview"}
                          </ListGroup.Item>
                        )}

                        <ListGroup.Item
                          as={AnchorLink}
                          to={`${pathname}#support`}
                          stripHash
                        >
                          {btn_request_sample &&
                          btn_request_sample.text.length > 0
                            ? btn_request_sample.text
                            : "Request Sample"}
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={9}>
                      {catParent && (
                        <Box mb={4}>
                          <div className="d-flex my-3 justify-content-left">
                            <div className="font-weight-bold ml-2">
                              {" >>"}
                              <Link
                                to={slugCat(
                                  catParent.uid,
                                  gContext.currentLang
                                )}
                                className="breadcrumb-link"
                              >
                                {catParent.data.title.text}
                              </Link>
                            </div>
                            <div className="font-weight-bold ml-2">
                              <div>
                                {" >>"}
                                <a
                                  href="#"
                                  className="breadcrumb-link"
                                  onClick={e => {
                                    e.preventDefault()
                                    setTabActiveKey("info")
                                  }}
                                >
                                  {cat.data.title.text}
                                </a>
                              </div>
                            </div>
                          </div>
                        </Box>
                      )}

                      <Tab.Content>
                        <Tab.Pane eventKey="info">
                          {slices.map(({ slice_type, primary, items }, i) =>
                            renderSlices(slice_type, primary, items, `key_${i}`)
                          )}
                        </Tab.Pane>
                        {allProducts.length > 0 && (
                          <Tab.Pane eventKey="products">
                            <Box>
                              <AccordionStyled
                                defaultActiveKey={allProducts[0].uid}
                              >
                                {allProducts.map(product => {
                                  const { body: slices = [] } = product.data

                                  return (
                                    <Card
                                      key={product.uid}
                                      className={`accordion-card-subcat ${
                                        activeKeyProduct === product.uid
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={product.uid}
                                        className={
                                          activeKeyProduct === product.uid
                                            ? "accordion-header active"
                                            : "accordion-header"
                                        }
                                        onClick={e => {
                                          if (
                                            activeKeyProduct !== product.uid
                                          ) {
                                            setActiveKeyProduct(product.uid)
                                          } else {
                                            setActiveKeyProduct(null)
                                          }
                                        }}
                                      >
                                        <Link
                                          to={slugProd(
                                            product.uid,
                                            product.data.sub_category.uid,
                                            product.data.category.uid,
                                            gContext.currentLang
                                          )}
                                        >
                                          {product.data.title.text}
                                        </Link>
                                        <ToggleButton
                                          onClick={() => {
                                            if (
                                              activeKeyProduct !== product.uid
                                            ) {
                                              setActiveKeyProduct(product.uid)
                                            } else {
                                              setActiveKeyProduct(null)
                                            }
                                          }}
                                          className={
                                            activeKeyProduct === product.uid
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <FaChevronRight size={12} />
                                        </ToggleButton>
                                      </Accordion.Toggle>
                                      <Accordion.Collapse
                                        eventKey={product.uid}
                                      >
                                        <Card.Body>
                                          <div>
                                            {slices.map(
                                              (
                                                { slice_type, primary, items },
                                                i
                                              ) =>
                                                renderSlices(
                                                  slice_type,
                                                  primary,
                                                  items,
                                                  `key_${i}`
                                                )
                                            )}
                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  )
                                })}
                              </AccordionStyled>
                            </Box>
                          </Tab.Pane>
                        )}
                        {allOptions.length > 0 && (
                          <Tab.Pane eventKey="options">
                            <Box>
                              <AccordionStyled
                                defaultActiveKey={allOptions[0].uid}
                              >
                                {allOptions.map(option => {
                                  const { body: slices = [] } = option.data

                                  return (
                                    <Card
                                      key={option.uid}
                                      className={`accordion-card-subcat ${
                                        activeKeyOption === option.uid
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={option.uid}
                                        className={
                                          activeKeyOption === option.uid
                                            ? "accordion-header active"
                                            : "accordion-header"
                                        }
                                        onClick={() => {
                                          if (activeKeyOption !== option.uid) {
                                            setActiveKeyOption(option.uid)
                                          } else {
                                            setActiveKeyOption(null)
                                          }
                                        }}
                                      >
                                        <Link
                                          to={slugProd(
                                            option.uid,
                                            option.data.sub_category.uid,
                                            option.data.category.uid,
                                            gContext.currentLang
                                          )}
                                        >
                                          {option.data.title.text}
                                        </Link>
                                        <ToggleButton
                                          onClick={() => {
                                            if (
                                              activeKeyOption !== option.uid
                                            ) {
                                              setActiveKeyOption(option.uid)
                                            } else {
                                              setActiveKeyOption(null)
                                            }
                                          }}
                                          className={
                                            activeKeyOption === option.uid
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <FaChevronRight size={12} />
                                        </ToggleButton>
                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey={option.uid}>
                                        <Card.Body>
                                          <div>
                                            {slices.map(
                                              (
                                                { slice_type, primary, items },
                                                i
                                              ) =>
                                                renderSlices(
                                                  slice_type,
                                                  primary,
                                                  items,
                                                  `key_${i}`
                                                )
                                            )}
                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  )
                                })}
                              </AccordionStyled>
                            </Box>
                          </Tab.Pane>
                        )}
                        {allSpecs.length > 0 && (
                          <Tab.Pane eventKey="specs">
                            <Box>
                              <AccordionStyled
                                defaultActiveKey={allSpecs[0].uid}
                              >
                                {allSpecs.map(spec => {
                                  const { body: slices = [] } = spec.data

                                  return (
                                    <Card
                                      key={spec.uid}
                                      className={`accordion-card-subcat ${
                                        activeKeySpec === spec.uid
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={spec.uid}
                                        className={
                                          activeKeySpec === spec.uid
                                            ? "accordion-header active"
                                            : "accordion-header"
                                        }
                                        onClick={e => {
                                          if (activeKeySpec !== spec.uid) {
                                            setActiveKeySpec(spec.uid)
                                          } else {
                                            setActiveKeySpec(null)
                                          }
                                        }}
                                      >
                                        <Link
                                          to={slugProd(
                                            spec.uid,
                                            spec.data.sub_category.uid,
                                            spec.data.category.uid,
                                            gContext.currentLang
                                          )}
                                        >
                                          {spec.data.title.text}
                                        </Link>
                                        <ToggleButton
                                          onClick={() => {
                                            if (activeKeySpec !== spec.uid) {
                                              setActiveKeySpec(spec.uid)
                                            } else {
                                              setActiveKeySpec(null)
                                            }
                                          }}
                                          className={
                                            activeKeySpec === spec.uid
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <FaChevronRight size={12} />
                                        </ToggleButton>
                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey={spec.uid}>
                                        <Card.Body>
                                          <div>
                                            {slices.map(
                                              (
                                                { slice_type, primary, items },
                                                i
                                              ) =>
                                                renderSlices(
                                                  slice_type,
                                                  primary,
                                                  items,
                                                  `key_${i}`
                                                )
                                            )}
                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  )
                                })}
                              </AccordionStyled>
                            </Box>
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapperStyledSubCats>
    </>
  )
}
export default SubCategory

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicCommon(lang: { eq: $lang }) {
      lang
      data {
        btn_back {
          text
        }
        btn_home {
          text
        }
        btn_request_sample {
          text
        }
      }
    }

    allPrismicProduct(
      sort: { fields: data___order, order: ASC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        uid
        lang
        data {
          type
          title {
            text
          }
          category {
            uid
          }
          sub_category {
            uid
          }
          body {
            ... on PrismicProductBodySection {
              slice_type
              items {
                div {
                  raw
                }
              }
              primary {
                title1 {
                  text
                }
              }
            }
            ... on PrismicProductBodyImageGallery {
              slice_type
              primary {
                title1 {
                  text
                }
              }
              items {
                caption {
                  text
                }
                image {
                  url
                }
              }
            }
            ... on PrismicProductBodyTable {
              slice_type
              primary {
                total_cells
                title1 {
                  text
                }
              }
              items {
                cell_1 {
                  raw
                }
                cell_2 {
                  raw
                }
                cell_3 {
                  raw
                }
                cell_4 {
                  raw
                }
                cell_5 {
                  raw
                }
                cell_6 {
                  raw
                }
                cell_7 {
                  raw
                }
                cell_8 {
                  raw
                }
                cell_9 {
                  raw
                }
                cell_10 {
                  raw
                }
                cell_11 {
                  raw
                }
                cell_12 {
                  raw
                }
              }
            }
          }
        }
      }
    }

    prismicSubCategory(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }

        title {
          text
        }
        cover_image {
          url
        }
        has_product_database
        has_technical_overview

        docs_group {
          title1 {
            text
          }
          doc_link {
            url
          }
        }

        btn_product_database_title {
          text
        }
        btn_technical_overview_title {
          text
        }
        sub_category_title {
          text
        }
        sub_category_info_title {
          text
        }
        products_title {
          text
        }
        options_title {
          text
        }
        specifications_title {
          text
        }

        category {
          document {
            ... on PrismicCategory {
              data {
                title {
                  text
                }
                cover_image {
                  url
                }
                color_brand
                color_hover
                color_active
              }
              uid
            }
          }
        }

        body {
          ... on PrismicSubCategoryBodySection {
            slice_type
            items {
              div {
                raw
              }
            }
            primary {
              title1 {
                text
              }
            }
          }
          ... on PrismicSubCategoryBodyImageGallery {
            slice_type
            primary {
              title1 {
                text
              }
            }
            items {
              caption {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicSubCategoryBodyTable {
            slice_type
            primary {
              total_cells
              title1 {
                text
              }
            }
            items {
              cell_1 {
                raw
              }
              cell_2 {
                raw
              }
              cell_3 {
                raw
              }
              cell_4 {
                raw
              }
              cell_5 {
                raw
              }
              cell_6 {
                raw
              }
              cell_7 {
                raw
              }
              cell_8 {
                raw
              }
              cell_9 {
                raw
              }
              cell_10 {
                raw
              }
              cell_11 {
                raw
              }
              cell_12 {
                raw
              }
            }
          }
        }
      }
    }
  }
`
